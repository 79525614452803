<template>
    <CraftLink
        v-if="isCraftLink"
        class="back-link"
        :link="link"
    >
        <BaseIcon
            icon="arrow-left-alt"
        />
        <span class="back-link__label">
            <slot />
        </span>
    </CraftLink>
    <button
        v-else-if="useHistory"
        class="back-link back-link--button"
        @click="historyBack"
    >
        <BaseIcon icon="arrow-left-alt" />
        <span class="back-link__label">
            <slot />
        </span>
    </button>
    <NuxtLink
        v-else
        class="back-link"
        :to="uri"
    >
        <BaseIcon icon="arrow-left-alt" />
        <span class="back-link__label">
            <slot />
        </span>
    </NuxtLink>
</template>

<script setup>
const emit = defineEmits(['click']);
const router = useRouter();

defineProps({
    link: {
        type: Object,
        default: () => {}
    },
    isCraftLink: {
        type: Boolean,
        default: true
    },
    uri: {
        type: String,
        default: ''
    },
    useHistory: {
        type: Boolean,
        default: false
    }
});

function historyBack() {
    emit('click');
    router.go(-1);
}

</script>

<style src="./BackLink.less" lang="less"></style>
