import routerOptions0 from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.15_eslint@8.57.0_ioredis@5.4.1_less@4.2.0_b4wubwcvp5pw3sqt6lfm2nl7au/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/vercel/path0/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}