<template>
    <div
        class="contact-card"
        :data-theme="simpleTheme"
    >
        <div class="contact-card__header">
            <div class="contact-card__content">
                <h3>{{ title }}</h3>
                <template v-if="description">
                    <div v-html="description" /> <!-- eslint-disable-line vue/no-v-html -->
                </template>
            </div>
            <BaseImage
                class="contact-card__image contact-card__image--desktop"
                :image="employeeFreeAvatar?.[0] ?? employeeObj?.avatar?.[0]"
            />
        </div>

        <div class="contact-card__details">
            <BaseImage
                class="contact-card__image contact-card__image--mobile"
                :image="employeeFreeAvatar?.[0] ?? employeeObj?.avatar?.[0]"
            />
            <div class="contact-card__details-content">
                <div>
                    <b>{{ employeeFreeName ?? employeeObj?.name }}</b>
                    <p>{{ employeeFreeFunction ?? employeeObj?.function }}</p>
                </div>
                <div>
                    <a :href="'mailto:' + email">
                        {{ email }}
                    </a>
                    <a :href="'tel:' + phone">
                        {{ phone }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

const props = defineProps({
    simpleTheme: {
        type: String,
        default: 'light'
    },
    title: {
        type: String,
        default: null
    },
    description: {
        type: String,
        default: null
    },
    employee: {
        type: Object,
        default: null
    },
    employeeFreeName: {
        type: String,
        default: null
    },
    employeeFreeFunction: {
        type: String,
        default: null
    },
    employeeFreeEmail: {
        type: String,
        default: null
    },
    employeeFreePhone: {
        type: String,
        default: null
    },
    employeeFreeAvatar: {
        type: Array,
        default: null
    },
});

const { employee } = toRefs(props);
const employeeObj = computed(() => employee.value?.[0]);

const email = computed(() => props.employeeFreeEmail ?? employeeObj?.email ?? 'info@vfonds.nl');
const phone = computed(() => props.employeeFreePhone ?? employeeObj?.phone ?? '030 200 6833');
</script>

<style src="./ContactCard.less" lang="less"></style>
