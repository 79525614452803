import revive_payload_client_H0gf7E7dS0 from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.15_eslint@8.57.0_ioredis@5.4.1_less@4.2.0_b4wubwcvp5pw3sqt6lfm2nl7au/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_3DGAdfJdMA from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.15_eslint@8.57.0_ioredis@5.4.1_less@4.2.0_b4wubwcvp5pw3sqt6lfm2nl7au/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_KDum2UWhDM from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.15_eslint@8.57.0_ioredis@5.4.1_less@4.2.0_b4wubwcvp5pw3sqt6lfm2nl7au/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_1PfpqTxx3H from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.15_eslint@8.57.0_ioredis@5.4.1_less@4.2.0_b4wubwcvp5pw3sqt6lfm2nl7au/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_QqGHNPTAPr from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.15_eslint@8.57.0_ioredis@5.4.1_less@4.2.0_b4wubwcvp5pw3sqt6lfm2nl7au/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_shXBfU5uA9 from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.15_eslint@8.57.0_ioredis@5.4.1_less@4.2.0_b4wubwcvp5pw3sqt6lfm2nl7au/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_YbLAanG0rw from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.15_eslint@8.57.0_ioredis@5.4.1_less@4.2.0_b4wubwcvp5pw3sqt6lfm2nl7au/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_59O68PzUXW from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.3_magicast@0.3.4_rollup@4.20.0_typescript@5.5.4_vue@3.4.37_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_NhBPV8kr9P from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.15_eslint@8.57.0_ioredis@5.4.1_less@4.2.0_b4wubwcvp5pw3sqt6lfm2nl7au/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_e7GgmmIAzw from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_magicast@0.3.4_rollup@4.20.0_vue@3.4.37_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_C8A1HtGs20 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_magicast@0.3.4_rollup@4.20.0_vue@3.4.37_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_7ieX7ty1j4 from "/vercel/path0/node_modules/.pnpm/@digitalnatives+graphql-client@4.1.1_graphql@16.9.0_magicast@0.3.4_nuxt@3.12.4_@parcel+watche_jc6dh52p5b7fdtossfz3q6fbeu/node_modules/@digitalnatives/graphql-client/dist/runtime/plugin.mjs";
import plugin_0qmE5hKOOv from "/vercel/path0/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.3.4_rollup@4.20.0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
export default [
  revive_payload_client_H0gf7E7dS0,
  unhead_3DGAdfJdMA,
  router_KDum2UWhDM,
  payload_client_1PfpqTxx3H,
  navigation_repaint_client_QqGHNPTAPr,
  check_outdated_build_client_shXBfU5uA9,
  chunk_reload_client_YbLAanG0rw,
  plugin_vue3_59O68PzUXW,
  components_plugin_KR1HBZs4kY,
  prefetch_client_NhBPV8kr9P,
  switch_locale_path_ssr_e7GgmmIAzw,
  i18n_C8A1HtGs20,
  plugin_7ieX7ty1j4,
  plugin_0qmE5hKOOv
]