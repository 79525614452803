import type { RouterConfig } from '@nuxt/schema';

export default <RouterConfig>{
    scrollBehavior: (to, from, savedPosition) => {
        // if link is to same page, to top
        if (to === from) {
            window.location.reload();
        }

        return {
            left: 0,
            top: 0
        };
    },
};
