<template>
    <div>
        <slot name="before" />

        <template
            v-for="(contentBlock, index) in filteredContentBlocks"
            :key="`contentBlock_${index}`"
        >
            <component
                :is="map[contentBlock.__typename]"
                v-bind="contentBlock"
            />
        </template>

        <slot name="after" />
    </div>
</template>

<script setup>
const props = defineProps({
    contentBlocks: {
        type: Array,
        required: true,
        default: () => [],
    },
});

const { contentBlocks } = toRefs(props);

/* eslint-disable camelcase */
const map = {
    contentBlocks_richText_BlockType: resolveComponent('ContentBlocksText'),
    contentBlocks_video_BlockType: resolveComponent('ContentBlocksVideo'),
    contentBlocks_textVideo_BlockType: resolveComponent('ContentBlocksTextVideo'),
    contentBlocks_textImage_BlockType: resolveComponent('ContentBlocksTextImage'),
    contentBlocks_image_BlockType: resolveComponent('ContentBlocksImage'),
    contentBlocks_form_BlockType: resolveComponent('ContentBlocksForm'),
    contentBlocks_quote_BlockType: resolveComponent('ContentBlocksQuote'),
    contentBlocks_accordion_BlockType: resolveComponent('ContentBlocksAccordion'),
    contentBlocks_issuu_BlockType: resolveComponent('ContentBlocksIssuu'),
    contentBlocks_employee_BlockType: resolveComponent('ContentBlocksEmployee'),
    contentBlocks_callToAction_BlockType: resolveComponent('ContentBlocksCallToAction'),
    contentBlocks_button_BlockType: resolveComponent('ContentBlocksButton'),
    contentBlocks_featuredContent_BlockType: resolveComponent('ContentBlocksFeaturedContent'),
};
/* eslint-enable camelcase */

const shouldRender = (contentBlock) => {
    if (!contentBlock) {
        return false;
    }

    if (!contentBlock.__typename || !map[contentBlock.__typename]) {
        return false;
    }

    return true;
};

const filteredContentBlocks = computed(() => {
    return contentBlocks.value.filter((contentBlock) => shouldRender(contentBlock));
});
</script>

<style lang="less">
.content-block__call-to-action {
    margin-top: var(--spacing-sm);

    @media @q-md-min {
        margin-top: var(--spacing-md);
    }
}
</style>
