<template>
    <NuxtLayout
        class="error"
        name="default"
    >
        <div>
            <PageTemplateContent
                v-if="entry"
                :entry="entry"
            />
        </div>
    
        <!--        #fixme log errors to bugsnag-->
        <!--        <ErrorMessage :error="error" />-->
    </NuxtLayout>
</template>

<script setup>
import EntryQuery from '~/graphql/queries/Entry.graphql';

defineProps({
    error: {
        type: Object,
        required: false,
        default: null
    }
});

const { variables: defaultVariables } = useCraftGraphql();

const pageVariables = computed(() => {
    return {
        ...defaultVariables,
        section: 'notFound',
        uri: undefined // We don't need the URI for this query
    };
});

const pageResponse = await useAsyncQuery({
    query: EntryQuery,
    variables: pageVariables
});

const { craftEntry: entry } = useCraftPage(pageResponse);
useCallToActions(pageResponse);
</script>

<style lang="less" scoped>
.error {
    .page-container();
}
</style>
