<template>
    <BaseSection section-width="page" class="section--accordion">
        <template #default>
            <BaseAccordion :items="accordion" />
        </template>
    </BaseSection>
</template>

<script setup>
useHead({
    htmlAttrs: {
        'accordion-smooth-scroll': true,
    },
});

defineProps({
    accordion: {
        type: Array,
        default: () => [],
    },
});
</script>

<style lang="less">
[accordion-smooth-scroll] {
    scroll-behavior: smooth;
    scroll-padding-top: 2.5rem;

    @media @q-md-min {
        scroll-padding-top: 7.5rem;
    }
}

.section--accordion {
    @media @q-md-max {
        margin-left: calc(0px - var(--grid-side-gap-md));
        
        .section__container {
            padding-right: 0;
        }
    }
}
</style>
