import { usePageCTASTore } from '~/store/pageCTA';

export const useCallToActions = (response: MaybeRefOrGetter<Readonly<any>>) => {
    const ctaStore = usePageCTASTore();

    watchEffect(() => {
        const data = toValue(toValue(response)?.data ?? {});

        ctaStore.load(data?.entry?.callToAction ?? []);
    });
};
