<template>
    <div
        class="featured-content"
        :data-theme="theme"
    >
        <div class="featured-content__header">
            <h2>{{ title }}</h2>

            <ActionLink
                :link="link"
                :color="color"
            />
        </div>
        <div class="featured-content__container">
            <NuxtLink
                v-for="(item, index) in featured"
                :key="index"
                :to="`/${item.uri}`"
                class="featured-content__item"
            >
                <div class="featured-content__content">
                    <p class="featured-content__card-title">{{ item.cardTitle ?? item.title ?? item.heroTitle }}</p>

                    <span
                        v-if="item.__typename === 'projects_default_Entry'"
                        class="feature-content__card-subtitle"
                    >
                        <span class="sr-only">Type: </span>
                        {{ $t('general.project') }}
                    </span>
                    <span
                        v-if="item.__typename === 'news_default_Entry'"
                        class="feature-content__card-subtitle"
                    >
                        <span class="sr-only">Type: </span>
                        {{ $t('general.news') }}
                    </span>
                </div>
                <BaseImage
                    v-if="item.cardImage?.[0]"
                    class="featured-content__image"
                    :image="item.cardImage?.[0]"
                />
            </NuxtLink>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    theme: {
        type: String,
        default: null
    },
    title: {
        type: String,
        default: null
    },
    link: {
        type: Object,
        default: null
    },
    featured: {
        type: Array,
        default: null
    },
});

const color = computed(() => {
    return props.theme === 'cool' ? 'blue' : 'red';
});


</script>

<style src="./FeaturedContent.less" lang="less"></style>
