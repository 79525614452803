<template>
    <div
        class="page-cta"
        :class="{
            'page-cta--hidden-mob': arrivedState.bottom,
        }"
    >
        <template
            v-for="(callToAction, key) in ctaStore.callToActions"
            :key="key"
        >
            <CraftLink
                class="dn-button base-button"
                :class="{
                    'base-button--white': !callToAction.highlighted
                }"
                :link="callToAction.linkField"
                data-location="call-to-action"
            >
                {{ callToAction.linkField.customText ?? callToAction.title }}
            </CraftLink>
        </template>
    </div>
</template>

<script setup lang="ts">
import { useScroll } from '@vueuse/core';
import { usePageCTASTore } from '~/store/pageCTA';

const { arrivedState } = useScroll(process.client ? document : null, { offset: { bottom: 100 }});

const ctaStore = usePageCTASTore();
</script>

<style lang="less" src="./PageCallToActions.less" />
