<template>
    <NuxtLink
        :to="url"
        :target="link.target"
    >
        <slot>{{ link.text }}</slot>
    </NuxtLink>
</template>

<script setup>
import { HOME_URI } from '~/enums/page-uri';

const localePath = useLocalePath();

const props = defineProps({
    link: {
        type: Object,
        required: true,
        default: () => {}
    }
});

const { link } = toRefs(props);

/**
 * Given a variety of link types, return the URL to link to. Translate it, and replace any __home__ placeholders
*/
const url = computed(() => {
    if (!link.value) {
        return null;
    }

    let uri = null;
    if (link.value?.element?.uri) {
        uri = link.value.element.uri;
    } else if (link.value?.url) {
        // URL is absolute, so return it unchanged
        return link.value.url;
    }

    if (uri === HOME_URI) {
        return '/';
    }

    if (!uri) {
        return null;
    }

    // Strip any leading slashes
    uri = uri.replace(/^\/+/g, '');

    return localePath({
        path: `/${uri}`
    });
});
</script>
