<template>
    <figure class="issuu-embed">
        <div
            :data-url="url"
            class="issuuembed"
        />

        <figcaption
            v-if="caption || $slots.caption"
            class="sr-only"
        >
            <slot name="caption">{{ caption }}</slot>
        </figcaption>
    </figure>
</template>

<script setup>
useHead({
    script: [
        { src: 'https://e.issuu.com/embed.js', async: true, type: 'text/javascript' }
    ]
});

defineProps({
    url: {
        type: String,
        required: true
    },
    caption: {
        type: String,
        default: null
    }
});

onMounted(() => {
    setTimeout(() => {
        window?.IssuuReaders?.add();
    }, 50); // not sure why this works or is needed, but it does and it is
});
</script>

<style scoped lang="less">
.issuu-embed {
    height: 20rem;
    
    @media @q-md-min {
        height: 30rem;
    }
    
    @media @q-lg-min {
        height: 40rem;
    }
}

.issuuembed {
    height: 100%;
}
</style>
