<template>
    <header class="hero-info">
        <div
            v-if="!!singleImage || !!$slots.image"
            class="hero-info__image"
        >
            <slot name="image">
                <BaseImage
                    :image="singleImage"
                    loading="eager"
                    fetchpriority="high"
                />
            </slot>
        </div>
        
        <div class="hero-info__container">
            <BackLink
                v-if="backLink?.url || backLink?.element?.uri"
                :link="backLink"
                class="hero-info__back-link"
            >
                {{ backLink.text }}
            </BackLink>
    
            <hgroup
                v-if="!!title || !!$slots.title || !!$slots.titleElement"
                class="hero-info__title"
            >
                <slot name="titleElement">
                    <h1 v-if="props.title || !!$slots.title">
                        <slot name="title">{{ props.title }}</slot>
                    </h1>
                </slot>
            </hgroup>
    
            <div
                v-if="!!description || !!$slots.description"
                class="hero-info__description"
                :data-hero-overview="heroOverview"
            >
                <slot name="description">
                    <!-- eslint-disable -->
                    <div
                        v-if="description"
                        v-html="description"
                    />
                    <!-- eslint-enable -->
                </slot>
            </div>
        </div>
    </header>
</template>

<script setup>
const props = defineProps({
    image: {
        type: Object,
        default: null
    },
    title: {
        type: String,
        default: null
    },
    description: {
        type: String,
        default: null
    },
    heroOverview: {
        type: Boolean,
        default: false
    },
    backLink: {
        type: Object,
        default: null
    }
});

const { image } = toRefs(props);
const singleImage = computed(() => image.value?.[0] ?? null);
</script>

<style lang="less" src="./HeroInfo.less" />
