<template>
    <BaseSection section-width="page">
        <BaseColumns :is-reversed="mediaAlignment === 'left'">
            <template #column-text>
                <BaseRichText :content="richTextWithTitle" />

                <CraftLink
                    v-if="callToAction && callToAction.url"
                    :link="callToAction"
                    class="dn-button base-button content-block__call-to-action"
                />
            </template>

            <template #column-video>
                <BaseVideoEmbed :url="videoUrl">
                    <template
                        v-if="caption"
                        #caption
                    >
                        <div v-html="caption" /><!-- eslint-disable-line -->
                    </template>
                </BaseVideoEmbed>
            </template>
        </BaseColumns>
    </BaseSection>
</template>

<script setup>
const props = defineProps({
    title: {
        type: String,
        default: null
    },
    richText: {
        type: String,
        default: null
    },
    videoUrl: {
        type: String,
        default: null
    },
    caption: {
        type: String,
        default: null
    },
    mediaAlignment: {
        type: String,
        default: null
    },
    callToAction: {
        type: Object,
        default: null
    }
});

const richTextWithTitle = computed(() => {
    if (!props.title && !props.richText) {
        return;
    }

    if (!props.richText) {
        return `<h2>${props.title}</h2>`;
    }

    if (!props.title) {
        return props.richText;
    }

    return `<h2>${props.title}</h2>${props.richText}`;
});
</script>
