<template>
    <BaseSection section-width="content">
        <template #default>
            <BaseRichText :content="richText" />
        </template>
        
        <template
            v-slot:[mediaAlignment]
        >
            <div
                class="block-text-image"
                :media-alignment="mediaAlignment"
            >
                <BaseImage
                    v-if="singleImage"
                    sizes="(min-width: 1440px) 780px, (min-width: 780px) 50vw, 95vw"
                    :image="singleImage"
                    :title="singleImage.title"
                    :width="singleImage.width"
                    :height="singleImage.height"
                    class="block-text-image__image"
                />
                <div
                    class="block-text-image__text"
                    :data-width="sectionWidth"
                >
                    {{ caption }}
                </div>
            </div>
        </template>
    </BaseSection>
</template>

<script setup>
const props = defineProps({
    title: {
        type: String,
        default: null
    },
    richText: {
        type: String,
        default: null
    },
    image: {
        type: Array,
        default: null
    },
    mediaAlignment: {
        type: String,
        default: null
    },
    caption: {
        type: String,
        default: null
    },
    sectionWidth: {
        type: String,
        default: 'content-wide'
    },
});

const { image } = toRefs(props);

const singleImage = ref(image.value?.[0] ?? null);

</script>

<style lang="less">
.block-text-image[media-alignment='left'] {
    float: right;
}

.block-text-image__text {
    .typography-h6();
    
    margin-top: 1rem;
}

.block-text-image__image,
.block-text-image {
    @media @q-md-min {
        max-width: 20rem;
    }
}
</style>

