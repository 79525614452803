<template>
    <DnAccordion
        ref="accordion"
        :items="items"
        class="base-accordion"
    >
        <template #title="{ item }">
            <h4 class="base-accordion__header">
                {{ item.title }}
            </h4>
        </template>

        <template #default="{ item }">
            <div>
                <div v-html="item.content" /> <!-- eslint-disable-line vue/no-v-html -->
                <CraftLink
                    v-if="item.linkField?.url || item.linkField?.element?.uri"
                    class="dn-button base-button"
                    :link="item.linkField"
                >
                    {{ item.linkField.customText ?? item.linkField.text }}
                </CraftLink>
            </div>
        </template>

        <template #icon>
            <BaseIcon class="add" icon="add" />
            <BaseIcon class="remove" icon="remove" />
        </template>
    </DnAccordion>
</template>

<script setup>
import { ref } from 'vue';
import DnAccordion from '@digitalnatives/accordion';
import BaseIcon from '@/components/BaseIcon/BaseIcon.vue';

const accordion = ref(null);

defineProps({
    items: {
        type: Array,
        required: true,
        default() { return []; }
    }
});
</script>

<style src="./BaseAccordion.less" lang="less"></style>
