<template>
    <section
        class="section"
        :data-width="sectionWidth"
    >
        <div class="section__container">
            <div
                v-if="!!$slots.title"
                class="section__header"
            >
                <h2 class="section__title">
                    <slot name="title" />
                </h2>
            </div>

            <div class="section__body">
                <div
                    v-if="!!$slots.left"
                    class="section__body-left"
                >
                    <slot name="left" />
                </div>
    
                <div class="section__body-center">
                    <slot />
                </div>
                
                <div
                    v-if="!!$slots.right"
                    class="section__body-right"
                >
                    <slot name="right" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        sectionWidth: {
            type: String,
            default: 'page',
            validator(value) {
                return ['page', 'content', 'content-wide', 'bleed'].includes(value);
            }
        }
    }
};
</script>

<style lang="less" src="./BaseSection.less" />
